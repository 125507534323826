@import "../../styles/index.scss";

.liveRadioContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    & > .liveRadioInner {
        display: flex;
        padding: 45px;
        background-color: var(--darkTwo);
        color: var(--light);
        border: none;
        border-radius: 15px;
        gap: 25px;
        align-items: center;
        width: auto;

        & > .imgArea {
            width: 380px;
            height: 380px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;

            & > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                cursor: pointer;
                transition: 0.2s;
            }
            &:hover {
                & > img {
                    transform: scale(1.1);
                }
            }
        }
        & > .nowPlaying {
            width: 380px;
            height: 380px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: center;
            box-sizing: border-box;

            & > .title {
                & > marquee,
                & > div {
                    color: #61bdee;
                    font-weight: 500;
                    font-size: 30px;
                }
                & > span {
                    color: #6662c2;
                }
            }

            & > .progress {
                width: 100%;
                gap: 4px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                position: relative;

                & > .liveTextCtnr {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    margin-right: 5px;

                    & > div {
                        width: 14px;
                        height: 14px;
                        border-radius: 50%;
                        background-color: red;
                        animation: animate 0.5s linear alternate infinite;

                        @keyframes animate {
                            from {
                                opacity: 0;
                            }
                            to {
                                opacity: 1;
                            }
                        }
                    }
                    & > span {
                        font-size: 16px;
                    }
                }

                & > .progressTime {
                    position: absolute;
                    top: -4px;
                    font-size: 14px;
                    color: var(--light);
                    opacity: 0;
                    transform: translateX(-50%);
                }

                & > progress {
                    width: 100%;
                    margin-top: 20px;
                    border-radius: 7px;
                    height: 10px;
                    cursor: pointer;
                    border: none;

                    &::-webkit-progress-bar {
                        background-color: var(--bg-dark);
                        border-radius: 7px;
                    }
                    &::-webkit-progress-value {
                        background-color: var(--light);
                        border-radius: 7px;
                    }
                }
                & > progress {
                    &:hover {
                        & ~ .progressTime {
                            opacity: 1;
                        }
                    }
                }
                & > span {
                    font-size: 16px;
                }
            }
            & > .btnCtnr {
                display: flex;
                justify-content: center;
                align-items: center;
                & > .playBtn {
                    background-color: transparent;
                    border: none;
                    border-radius: 50%;
                    cursor: pointer;
                    color: #40b1ee;
                    width: 70px;
                    height: 70px;
                    transition: 0.15s;

                    &:hover {
                        transform: scale(1.1);
                        color: rgba(64, 177, 238, 0.9);
                    }

                    & > svg {
                        width: inherit;
                        height: inherit;
                    }
                }
            }
            & > .volumeCtnr {
                display: flex;
                flex-direction: column;
                gap: 5px;

                & > input[type="range"] {
                    -webkit-appearance: none;
                    height: 10px;
                    background-color: var(--bg-dark);
                    border-radius: 15px;
                    box-shadow: inset 0 0 5px var(--bg-dark);
                    outline: none;
                    border-radius: 10px;

                    &:hover {
                        opacity: 1;
                    }
                    &::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        appearance: none;
                        width: 15px;
                        height: 15px;
                        background-color: var(--blue);
                        border-radius: 50%;
                        cursor: pointer;
                        transition: 0.15s;
                    }
                    &::-webkit-slider-thumb:hover {
                        transform: scale(1.3);
                    }
                    &::-moz-range-thumb {
                        width: 15px;
                        height: 15px;
                        background-image: radial-gradient(#ddd, #eee);
                        border-radius: 50%;
                        cursor: pointer;
                    }
                }
                & > .rangeValue {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 16px;

                    & > .volumeOff,
                    & > .volumeUp {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

@media (width < 950px) {
    .liveRadioContainer {
        padding: 0 20px;
        & > .liveRadioInner {
            gap: 25px;
            align-items: center;
            width: 100%;
        }
    }
}
@media (width < 870px) {
    .liveRadioContainer {
        & > .liveRadioInner {
            padding: 20px;
            display: flex;
            & > .imgArea {
                flex: 1;
                height: 280px;
                width: auto;
            }
            & > .nowPlaying {
                flex: 1;
                height: 280px;
                width: auto;
                padding: 20px 0;
                gap: 0;
            }
        }
    }
}
@media (width < 620px) {
    .liveRadioContainer {
        & > .liveRadioInner {
            flex-direction: column;
            border-radius: 10px;
            gap: 10px;
            & > .imgArea {
                height: 280px;
                width: 100%;
            }
            & > .nowPlaying {
                height: 280px;
                width: 100%;
                padding: 0;
                gap: 0;

                & > .title {
                    & > marquee,
                    & > div {
                        color: #61bdee;
                        font-weight: 500;
                        font-size: 24px;
                    }
                    & > span {
                        color: #6662c2;
                    }
                }
            }
        }
    }
}
