@import "../../styles/index.scss";

.navbar {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: 1;
    grid-template-areas: "logo marquee social";
    gap: 100px;
    padding: 20px 5%;

    .logo {
        grid-area: logo;
        @include center;
        & > a {
            @include center;
            width: 150px;
            position: relative;
            & > img {
                width: 100%;
                height: 100%;
                transform: translateY(-5px);
            }
            & > svg {
                position: absolute;
                right: -10px;
                bottom: 5px;
                animation: logoMusicIconAnimation 4s ease-in-out infinite;
                transform: rotate(-15deg);
                font-size: 2.3rem;
                color: #39a2e3;
            }
        }
    }
    & > .marquee {
        grid-area: marquee;
        background-color: var(--darkTwo);
        color: var(--light);
        @include center;
        padding: 6px 20px;
        border-radius: 8px;
    }
    & > .socialMedia {
        grid-area: social;
        display: flex;
        align-items: center;
        gap: 10px;

        & > * {
            @include center;
            color: var(--light);
            background-color: transparent;
            border: none;
            cursor: pointer;
            transition: 0.15s;

            & > * {
                font-size: 28px;
            }

            &:hover {
                color: var(--blue);
                transform: scale(1.2);
            }

            &:last-child {
                background-color: var(--darkTwo);
                border-radius: 50%;
                padding: 4px;
            }
        }
    }
}

@media (width < 1250px) {
    .navbar {
        gap: 50px;
        padding: 20px 50px;
    }
}
@media (width < 800px) {
    .navbar {
        grid-template-columns: auto auto;
        grid-template-rows: 2;
        grid-template-areas:
            "logo social"
            "marquee marquee";
        gap: 10px;
        padding: 20px;

        & > .logo {
            width: 100px;
            justify-content: flex-start;

            & > a {
                & > svg {
                    right: -10px;
                    bottom: 0px;
                    font-size: 1.8rem;
                }
            }
        }
        & > .socialMedia {
            justify-content: flex-end;
        }
    }
}

@keyframes logoMusicIconAnimation {
    0% {
        transform: translateY(-2px) rotate(-15deg);
    }
    5% {
        transform: translateY(2px) rotate(-15deg);
    }
    10% {
        transform: translateY(-2px) rotate(-15deg);
    }
    15% {
        transform: translateY(2px) rotate(-15deg);
    }
    20%,
    100% {
        transform: translateY(0) rotate(-15deg);
    }
}
