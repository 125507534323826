:root {
    --dark: #ffffff;
    --bg-dark: #dddddd;
    --darkTwo: #ffffff;
    --light: #191a1c;
    --light-only: #ffffff;
    --blue: #40b1ee;

    --inputBg: #eeeeee;
}

body.dark {
    --dark: #191a1c;
    --darkTwo: #232528;
    --bg-dark: #191a1c;
    --light: #ffffff;

    --inputBg: #232528;
}
