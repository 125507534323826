@import "../../styles/index.scss";

.liveRadioArea {
    margin: auto auto;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 40px;
    padding: 20px 0 0 0;

    & > .categoryButtons {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 30px;

        & > button {
            background-color: var(--darkTwo);
            color: #dcdcdc;
            border: none;
            border-radius: 10px;
            padding: 6px 30px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 0 10px var(--darkTwo);
            cursor: pointer;
            transition: 0.15s;

            &:hover {
                & > span {
                    transform: rotate(-30deg) translate(30px, 20px) scale(1.2);
                }
            }
            & > img {
                width: 80px;
                transform: translateX(-10px);
            }
            & > span {
                color: #39a2e3;
                position: absolute;
                font-family: "Kalam", cursive;
                font-weight: bold;
                font-size: 24px;
                filter: drop-shadow(0 0 5px var(--dark));
                transform: rotate(-30deg) translate(30px, 20px);
                transition: 0.3s;
            }
        }
    }
    & > .scrollCtnr {
        height: 40px;
        width: 25px;
        & > a {
            & > .scrollDown {
                -webkit-animation: scrollDownFade 6s ease;
                animation: scrollDownFade 6s ease;
                border: 1px solid #3ca9ee;
                border-radius: 2.5rem;
                width: 1.5rem;
                cursor: pointer;
                height: 2.5rem;
                position: absolute;

                &::after,
                &::before {
                    content: "";
                    border: 1px solid #3ca9ee;
                    border-left: transparent;
                    border-top: transparent;
                    height: 0.5rem;
                    left: 50%;
                    position: absolute;
                    top: 20%;
                    -webkit-animation: scroll-down 1s ease-in-out infinite;
                    animation: scroll-down 1s ease-in-out infinite;
                    -webkit-transform: translate(-50%, -100%) rotate(45deg);
                    transform: translate(-50%, -100%) rotate(45deg);
                    width: 0.5rem;
                }
                &::after {
                    -webkit-animation-delay: 0.3s;
                    animation-delay: 0.3s;
                }
            }
        }
    }
}

@media (width < 949px) {
    .liveRadioArea {
        & > .categoryButtons {
            display: grid;
            width: 100%;
            grid-template-columns: repeat(4, 1fr);
            padding: 20px;
        }
    }
}
@media (width < 700px) {
    .liveRadioArea {
        & > .categoryButtons {
            padding: 0 20px;
            display: grid;
            width: 100%;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, auto);
        }
    }
}

@keyframes scroll-down {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    60% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        top: 90%;
    }
}

@keyframes lastMusicCardFade {
    0% {
        opacity: 0;
        left: -8rem;
    }
    100% {
        opacity: 1;
        left: -6rem;
    }
}

@keyframes nextMusicCardFade {
    0% {
        opacity: 0;
        right: -8rem;
    }
    100% {
        opacity: 1;
        right: -6rem;
    }
}
