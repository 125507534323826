@import "../../styles/index.scss";

.request-form-con-cover {
    display: none;
    animation: requestFormFade 0.5s ease;
}
@keyframes requestFormFade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.request-form-con {
    position: fixed;
    top: 0;
    width: 100%;
    min-height: 100vh;
    background-color: #16293367;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 0 10px;
}
.request-form-header {
    text-align: center;
    color: #3ca9ee;
    font-weight: 600;
    font-size: 1.25rem;
}
.request-form {
    padding: 1.5rem 2.5rem;
    background-color: var(--dark);
    position: relative;
    border-radius: 1rem;
}

.request-exit-button {
    color: #3ca9ee;
    font-weight: 600 !important;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    cursor: pointer;
}
.request-submit-button {
    -webkit-box-shadow: 0px 3px 0.75rem 8px #3caaee4b;
    box-shadow: 0px 3px 0.75rem 8px #3caaee14;
    margin-top: 2rem;
    cursor: pointer;
    background-color: var(--blue);
    border: 0;
    color: white;
    padding: 10px 60px;
    font-family: "Poppins";
    border-radius: 0.5rem;
    font-weight: 500;
    font-size: 0.75rem;
}
.input-submit {
    text-align: center;
}
.input-label {
    color: var(--light);
    font-weight: 500;
    font-size: 14px;
    margin-top: 1rem;
}
.input-artist-music {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;

    & > div {
        input {
            width: 100%;
        }
    }
}
.get-input {
    border-radius: 0.5rem;
    padding: 0.5rem 0.75rem;
    background-color: var(--inputBg);
    outline: none;
    border: 0;
    font-family: "Poppins" !important;
    font-size: 0.9rem;
    color: #3ca9ee;
    font-weight: 500;
    width: 100%;
    margin-top: 0.25rem;
}

@media (width < 510px) {
    .input-artist-music {
        gap: 0;
        & > div {
            width: 100%;
        }
    }
    .request-form {
        padding: 20px;
    }
    .request-submit-button {
        width: 100%;
    }
}
