@import "../../styles/colors";
@import "../../styles/mixin";

.Toast {
    padding: 10px 20px;
    background-color: var(--light);
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 400;
    color: var(--dark);

    & > .success,
    & > .error {
        font-size: 20px;
    }

    & > .success {
        color: #16a34a;
    }
    & > .error {
        color: #dc2626;
    }
    & > .promise {
        color: var(--blue);
        animation: promiseAnimate 1s linear infinite;
    }
}

@keyframes promiseAnimate {
    to {
        transform: rotate(0);
    }
    from {
        transform: rotate(360deg);
    }
}
