#root {
    overflow: auto;
    background-color: var(--bg-dark);
    font-size: 18px;
}
html,
body {
    overflow: hidden;
}

.paginate_button.disabled {
    background-color: #eee !important;
}
span > .paginate_button.current {
    background-color: var(--blue) !important;
    color: #eee !important;
}

td.dataTables_empty {
    color: var(--light);
}

.request-form-button {
    position: fixed;
    top: 140px;
    right: 5%;
    background-color: var(--light);
    padding: 8px 25px;
    border-radius: 10px;
    user-select: none;
    cursor: pointer;
    color: var(--blue);
    font-size: 16px;
    font-weight: 500;
    box-shadow: 0 0 10px var(--blue);
    display: flex;
    align-items: center;
    gap: 5px;
    z-index: 999;

    & > svg {
        font-size: 18px;
    }
}
.loading-screen {
    @include center;
    height: 100%;
    flex-direction: column;
    color: var(--light);
}

@media (width < 949px) {
    .request-form-button {
        right: 20px;
        top: 100px;
        padding: 10px;
        & > span {
            display: none;
        }
        & > svg {
            font-size: 20px;
        }
    }
}
