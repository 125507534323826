@import url("https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&display=swap");
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
    scroll-behavior: smooth;
    outline: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
html,
body,
#root {
    height: 100%;
}
body {
    overflow: auto !important;
}
::-webkit-scrollbar {
    opacity: 0;
    visibility: hidden;
    width: 0px;
}
