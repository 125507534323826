@import "../../styles/index.scss";

.generalRadioArea {
    margin: 80px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "last5list trackListCon discord";
    gap: 5%;
    padding: 0 5%;
    align-content: center;
    place-items: center;

    & > .last5list {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        grid-area: last5list;
        background-color: var(--darkTwo);
        border-radius: 10px;
        padding: 20px;
        -webkit-box-shadow: 0px 3px 1rem 8px rgba(61, 61, 61, 0.103);
        box-shadow: 0px 3px 1rem 8px rgba(61, 61, 61, 0.103);

        & > .last5listHeader {
            color: var(--light);
            font-weight: 600;
            font-size: 16px;
            text-align: center;
            margin-bottom: 1rem;
        }
        & > .historyList {
            list-style-type: none;
            padding: 0;
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 10px !important;

            & > .historyItem {
                display: flex;
                align-items: center;
                gap: 10px;
                color: var(--light);
                flex: 1;

                & > .historyNumber {
                    width: 20px;
                    text-align: center;
                    color: var(--light);
                }
                & > .historyArt {
                    height: 60px;
                    width: 60px;
                    border-radius: 7px;
                    cursor: pointer;
                    overflow: hidden;

                    &:hover {
                        & > img {
                            transform: scale(1.1);
                        }
                    }

                    & > img {
                        height: inherit;
                        width: inherit;
                        object-fit: cover;
                        transition: 0.2s;
                    }
                }
                & > .historyArtistTitle {
                    display: flex;
                    flex-direction: column;
                    font-size: 18px;
                    & > .historyTitle {
                        color: var(--light);
                        font-weight: 500;
                    }
                    & > .historyArtist {
                        color: #40b1ee;
                        font-size: 14px;
                    }
                }
                & > .historyPlayedAt {
                    font-size: 16px;
                    margin-left: auto;
                    white-space: nowrap;
                }
            }
        }
    }
    & > .discord {
        width: 100%;
        height: 100%;
        background-color: var(--darkTwo);
        box-sizing: border-box;
        grid-area: discord;
        padding: 20px;
        border-radius: 10px;
        -webkit-box-shadow: 0px 3px 1rem 8px rgba(61, 61, 61, 0.103);
        box-shadow: 0px 3px 1rem 8px rgba(61, 61, 61, 0.103);
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 8px;

        & > .discordAreaHeader {
            text-align: center;

            & > img {
                width: 100px;
            }
        }
        & > .discordAreaInfo {
            display: flex;
            align-items: center;
            justify-content: space-between;

            & > .discordAreaMember,
            & > .discordAreaChannel {
                background-color: var(--bg-dark);
                color: var(--light);
                font-size: 14px;
                font-weight: 600;
                border-radius: 10px;
                padding: 10px;
                width: 150px;
                @include center;
                height: 32px;
            }
        }
        & > .discordMessageArea {
            overflow: auto;
            flex: 1;
            max-height: 320px;
            padding: 0 5px;
            scroll-behavior: auto;
            display: flex;
            flex-direction: column;
            gap: 5px;

            &::-webkit-scrollbar {
                width: 8px;
            }
            &::-webkit-scrollbar-track {
                background: #dfdfdf;
                border-radius: 0.5rem;
            }
            &::-webkit-scrollbar-thumb {
                background: #717679;
                border-radius: 0.5rem;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: #49545a;
            }

            & > .messagesCtnr {
                color: var(--light);
                font-size: 16px;
                display: flex;
                align-items: start;
                gap: 5px;
                & > .username {
                    color: var(--blue);
                    font-weight: 600;
                    user-select: none;
                }
                & > p {
                    flex: 1;
                    inline-size: 200px;
                    overflow: hidden;
                    overflow-wrap: break-word;
                }
            }
        }
        & > .discordInviteArea {
            width: 100%;
            position: absolute;
            bottom: 1rem;
            box-sizing: border-box;
            width: 100%;
            position: relative !important;
            bottom: 0 !important;

            & > .discordSendMessageArea {
                & > .discordSendMessage {
                    padding: 0.5rem 0.7rem;
                    box-sizing: border-box;
                    background-color: #e6e6e6;
                    color: #1c2c35;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 0.8rem;
                    border-radius: 0.7rem;
                    opacity: 0.9;
                }
            }
            & > .discordInviteButton {
                display: none;
                width: 100%;
                box-sizing: border-box;

                & > a {
                    text-decoration: none;
                    @include center;
                    width: 100%;

                    & > div {
                        cursor: pointer;
                        padding: 0.5rem 0.7rem;
                        border-radius: 0.7rem;
                        background-color: #3ca9ee;
                        width: 100%;
                        width: 100%;
                        color: white;
                        font-size: 0.8rem;
                        text-align: center;
                        font-weight: 500;
                        -webkit-box-shadow: 0px 2px 1rem 8px #53bafa1d;
                        box-shadow: 0px 2px 1rem 8px #53bafa29;
                        transition: all 0.1s ease;
                        animation: dcInviteVibration 0.4s;

                        &:hover {
                            -webkit-box-shadow: 0px 3px 1.2rem 8px #53bafa4b;
                            box-shadow: 0px 3px 1.2rem 8px #53bafa4b;
                        }
                    }
                }
            }
        }
    }
    & > .trackListCon {
        border-radius: 10px;
        padding: 20px;
        height: 100%;
        width: 100%;
        background-color: var(--darkTwo);
        grid-area: trackListCon;

        & > div {
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 20px;

            h2 {
                color: var(--blue);
                font-weight: 500;
            }

            & > * {
                width: 100% !important;
            }
            & > div:nth-of-type(2) {
                & > label {
                    & > input {
                        background-color: var(--bg-dark);
                        border-radius: 3px;
                    }
                }
            }
            & > table {
                border-bottom: none;
                & > tbody {
                    & > tr {
                        border-radius: 3px;

                        &:nth-of-type(odd) {
                            background-color: var(--darkTwo);
                        }
                        & > td {
                            &:first-of-type {
                                display: flex;
                                flex-direction: column-reverse;
                                & > div {
                                    &:first-of-type {
                                        color: var(--blue);
                                        font-size: 14px;
                                    }
                                    &:last-of-type {
                                        color: var(--light);
                                        font-size: 17px;
                                        font-weight: 500;
                                    }
                                }
                            }
                            &:last-of-type {
                                padding: 0 !important;
                                padding-right: 10px !important;

                                & > button {
                                    width: 100% !important;
                                    cursor: pointer;
                                    border: none;
                                    background-color: var(--blue);
                                    color: #fff;
                                    padding: 6px 20px;
                                    font-weight: 500;
                                    transition: 0.3s;
                                    border-radius: 5px;

                                    &:hover {
                                        transform: scale(1.1);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            & > div:last-of-type {
                position: relative !important;
                padding: 0 10px !important;

                & > a {
                    background-color: var(--blue);
                    color: #fff;
                    @include center;

                    &:hover {
                        color: #fff !important;
                        transform: scale(1.1);
                    }
                }

                & > span {
                    & > a {
                        font-size: 16px !important;
                        background-color: var(--bg-dark);
                        color: var(--blue);
                    }
                }
            }
        }
    }
}

@media (width < 1400px) {
    .generalRadioArea {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas:
            "last5list trackListCon"
            "discord discord";
        align-content: center;
        gap: 40px;
        margin: 40px 0;
    }
}
@media (width < 1000px) {
    .generalRadioArea {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-template-areas:
            "last5list"
            "trackListCon"
            "discord";
        align-content: center;
    }
}
@media (width < 949px) {
    .generalRadioArea {
        padding: 0 20px;
    }
}
@media (width < 510px) {
    .generalRadioArea {
        .discord {
            & > .discordMessageArea {
                & > .messagesCtnr {
                    font-size: 14px;

                    & > p {
                        inline-size: 150px;
                    }
                }
            }

            & > .discordAreaInfo {
                & > .discordAreaMember,
                & > .discordAreaChannel {
                    width: 140px;
                }
            }
        }
    }
}

@media (width < 420px) {
    .generalRadioArea {
        .discord {
            & > .discordAreaInfo {
                & > .discordAreaMember,
                & > .discordAreaChannel {
                    width: 140px;
                }
            }
        }
    }
}

@keyframes dcInviteVibration {
    0% {
        opacity: 0;
        transform: rotate(3deg);
        transform: translateX(0rem);
    }
    10% {
        transform: rotate(3deg);
        transform: translateY(-0.3rem);
    }
    20% {
        transform: rotate(0deg);
        transform: translateX(-0.3rem);
    }
    30% {
        transform: rotate(3deg);
        transform: translateY(0.3rem);
    }
    40% {
        transform: rotate(3deg);
        transform: translateX(0.3rem);
    }
    50% {
        transform: rotate(0deg);
        transform: translateY(-0.3rem);
    }
    60% {
        transform: rotate(3deg);
        transform: translateX(-0.3rem);
    }
    70% {
        transform: rotate(0deg);
        transform: translateY(0.3rem);
    }
    80% {
        transform: rotate(3deg);
        transform: translateX(0.3rem);
    }
    90% {
        transform: rotate(3deg);
        transform: translateY(0rem);
    }
    100% {
        opacity: 1;
        transform: rotate(0deg);
        transform: translateX(0rem);
    }
}
